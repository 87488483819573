

<div class="card m-1" style=" display: flex; flex:1; box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);" id="pantalla">
    <div class="card-header p-1">
        <div class="flex-container">
            <div style="flex:100%;">
                <form id="formBuscar" class="form-inline ml-2" >
                    <input type="number" id="buscarOt" (keyup.enter)="buscar()" (click) = "$event.target.select()" name="table_search" class="form-control float-right" placeholder="NRO OT" style="width: 120px ;">
                    <button type="button" (click)="buscar()" class="btn btn-primary ml-2 elevation-1"><strong>Buscar</strong></button>
                    <button type="button" (click)="buscar()" class="btn btn-danger ml-2 elevation-1"><strong>Refrescar</strong></button>
                    <span style="font-size: 28px; margin-left: auto; margin-right: auto; float: right;"><strong>LISTADO GARANTIAS</strong></span>
                </form> 
            </div>
            <!--
            <div style="flex: 60%;">
                <h4 class="mb-0"> 
                    <span class="badge bg-danger mt-0 mb-0 ml-1" style="position: relative; float: right; font-size: larger;" data-toggle="tooltip" title ="Click para limpiar!!">
                        <i class="fa fa-filter"></i>
                        <span id="filtrados">0</span>
                    </span> 
                    <span class="badge bg-primary mt-0 mb-0" style="position: relative; float: right; font-size: larger;" >
                        <i class="fa fa-bars mr-1" ></i>
                        <span id="cant-registros">0</span>
                    </span> 
                </h4>
            </div> -->
        </div>
    </div>
    <div class="card-body p-0 table-responsive" style=" overflow: auto;">
        <table class="table table-sm table-head-fixed text-nowrap m-0 table-hover table-striped" id="tabla" #tabla1>
            <thead id="cab" #cab style="background-color: #dc3545;"></thead>
            <tbody id="det" #det style="font-size:14px;"></tbody>
        </table>
    </div>

<!--

<div class="container-fluid p-1" style="height:100%; min-height: 100%; display:flex; flex-direction:row; align-items: stretch; background-color: darkblue;">
    <div class="card" style="width: 100%; height: 100%;">
        <div class="card-header">
            <h4>Listado de Garantias !</h4>
        </div>
        <div class="card-body p-1">
            <div class="table-responsive">
                <table class="table m-0">
                    <thead id="cab"></thead>
                    <tbody id="det"></tbody>
                </table>
            </div>
        </div>
    </div>
</div>
-->