<div class="card m-1" style=" display: flex; flex:1; box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);" id="filtro">
    <div class="card-header p-1"> 
        <div class="flex-container"> 
            <div style="flex:100%;"> 
                <form id="formBuscar" class="form-inline ml-2" >
                    <input type="date" id="fechai" (click) = "$event.target.select()" name="table_search" class="form-control float-right" placeholder="" style="width: 170px ;">
                    <input type="date" id="fechaf" (click) = "$event.target.select()" name="table_search" class="form-control float-right" placeholder="" style="width: 170px ;">
                    <select class="form-control" id="sucursales" name="sucursales" required>
                        <option value="0">SUCURSALES</option>
                        <option value="1">KIA VICTORIA</option>
                        <option value="2">KIA MRA</option>
                    </select>                     
                    <button type="button" class="btn btn-primary ml-2 elevation-1" (click)="buscar()"><strong>Buscar</strong></button>
                    <button type="button" (click)="resetFiltro()" class="btn btn-danger ml-2 elevation-1"><strong>Limpiar</strong></button>

                    <span style="font-size: 28px; margin-left: auto; margin-right: auto;"><strong>REPORTES</strong></span>
                </form> 
            </div>
        </div>
    </div> 
    <div class="card-body">

        <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="custom-tabs-three-profile-tab2" data-toggle="pill" href="#custom-tabs-three-profile2" role="tab" aria-controls="custom-tabs-three-profile2" aria-selected="false">LISTADO</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">DINAMICO</a>
            </li>

        </ul>
        <div class="tab-content" id="custom-tabs-three-tabContent">

            <div class="tab-pane fade active show" id="custom-tabs-three-profile2" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab2">

                <div class="card m-1" style=" display: flex; flex:1; box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);" >
                    <div class="card-body p-0 table-responsive" style=" overflow: auto; height: 450px;"> 
                        <div class="container-fluid">
                            <div class="row">

                                <div class="col-xs-3">
                                    <ul>
                                        <li><b>Registros:</b> <span class="badge badge-success">Total <span class="badge badge-light" style="font-size: 12px;" id="totalRegistros"></span></span>  </li>
                                        <li><b>Garantias: </b> 
                                            <span class="badge badge-primary">Aprobados <span class="badge badge-light" style="font-size: 12px;" id="totalGaAprobados"></span></span> 
                                            <span class="badge badge-danger">Pendientes <span class="badge badge-light" style="font-size: 12px;" id="totalGaPendientes"></span></span>
                                            <span class="badge badge-success">Total <span class="badge badge-light" style="font-size: 12px;" id="totalGarantia"> </span></span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-xs-3">
                                    <ul>
                                        <li><b>Taller:</b>
                                            <!-- <span class="badge badge-primary">Aprobados <span class="badge badge-light" style="font-size: 12px;" id="totalTaAprobados"></span></span>  -->
                                            <span class="badge badge-danger">Pendientes <span class="badge badge-light" style="font-size: 12px;" id="totalTaPendientes"></span></span>
                                            <span class="badge badge-success">Total <span class="badge badge-light" style="font-size: 12px;" id="totalTaller"></span></span>                                            
                                        </li>
                                        <li><b>Hoy:</b> 
                                            <span class="badge badge-primary">Aprobados <span class="badge badge-light" style="font-size: 12px;" id="totalHoyAprobados"></span></span> 
                                            <span class="badge badge-danger">Pendientes <span class="badge badge-light" style="font-size: 12px;" id="totalHoyPendientes"></span></span>
                                            <span class="badge badge-warning">Cerrado <span class="badge badge-light" style="font-size: 12px;" id="totalHoyCerrado"></span></span>
                                            <span class="badge badge-success">Total <span class="badge badge-light" style="font-size: 12px;" id="totalHoy"></span></span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-xs-3">
                                    <ul>
                                        <!-- <li><b>Limpiar Filtros:</b>
                                            <span class="badge badge-warning">Limpiar <span id=""></span></span> 
                                        </li> -->
                                        <!-- <li><b>Hoy:</b> </li> -->
                                    </ul>
                                </div>

                            </div>
                        </div>
                        
                        <table class="table table-sm table-head-fixed text-nowrap m-0 table-hover table-striped" >
                            <thead id="cab-listado2"></thead>
                            <tbody id="det-listado2"></tbody>
                        </table>
                    </div>  
                </div>

            </div>

            <div class="tab-pane fade show" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                <div id="res"></div>
            </div>

        </div>
    </div>  
</div>
