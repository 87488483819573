import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.css']
})
export class ImpresionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
