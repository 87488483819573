<div class="container mt-2">
    <!-- Main node for this component -->
    <div class="card card-danger">
        <div class="card-header">
          <h3 class="card-title"><strong>NUEVO USUARIO</strong></h3>
        </div>
        <!-- /.card-header -->
        <!-- form start -->
        <form id="form1" class="was-validated">
          <div class="card-body p-0 ">

              <div class="row mr-1 ml-1">
                <div class="col-sm-6">
                  <div class="form-group">
                      <label for="idSolicitud"><i class="fa fa-bookmark" aria-hidden="true"></i> ID:</label>
                      <input type="text" class="form-control" id="id" name="id" placeholder="Solicitud" readonly>
                    </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label for="exampleInputEmail1"><i class="fa fa-star" aria-hidden="true"></i> ESTADO:</label>
                      <!-- <input type="text" class="form-control" id="estado" name="estado" placeholder="Estado" value="ACTIVO" readonly>-->
                      <select name="estado" id="estado" class="form-control"  required> 
                        <option value="ACTIVO" >ACTIVO</option> 
                        <option value="INACTIVO" >INACTIVO</option> 
                      </select>
                    </div>
                </div>
            </div>

              <div class="row mr-1 ml-1">
                <div class="col-sm-6">
                  <div class="form-group">
                      <label for="exampleInputEmail1"><i class="fa fa-cogs" aria-hidden="true"></i> NOMBRE USUARIO:</label>
                      <input type="text" class="form-control" id="nombre" name="nombre" placeholder="Nombre del Usuario" required>
                    </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                      <label for="exampleInputEmail1"><i class="fa fa-university" aria-hidden="true"></i> AREA:</label>
                      <!-- <input type="text" class="form-control" id="area" name="area" placeholder="AREA" required>-->
                      <select name="perfil" id="perfil" class="form-control" required>
                        <option value="JEFE GRUPO">JEFE GRUPO</option>    
                        <option value="GARANTIA">GARANTIA</option>    
                        <option value="JEFE TALLER">JEFE TALLER</option>    
                        <option value="REPUESTO">REPUESTO</option>    
                        <option value="ADMINISTRADOR">ADMINISTRADOR</option>    
                      </select>
                    </div>
                </div>
            </div>

            <div class="row mr-1 ml-1">
                <div class="col-sm-6">
                  <div class="form-group">
                      <label for="exampleInputEmail1"><i class="fa fa-user" aria-hidden="true"></i> USUARIO:</label>
                      <input type="text" class="form-control" id="usuario" name="usuario" placeholder="Codigo Usuario" required>
                    </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                      <label for=""><i class="fa fa-taxi" aria-hidden="true"></i> CONTRASEÑA:</label>
                      <input type="password" class="form-control" id="pass" name="pass" placeholder="pass" required>
                   </div>
                </div>

            </div>
            <div class="row mr-1 ml-1" >
              <div class="col-sm-6">
                <div class="form-group">
                    <label for="exampleInputEmail1"><i class="fa fa-paper-plane" aria-hidden="true"></i> NOTIFICACIONES (TELEGRAM):</label>
                    <!-- <input type="text" class="form-control" id="area" name="area" placeholder="AREA" required>-->
                    <select name="chat_id" id="chat_id" class="form-control" required>
                    </select>
                  </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                    <label for="exampleInputEmail1"><i class="fa fa-paper-plane" aria-hidden="true"></i> SUCURSAL:</label>
                    <select name="idSucursal" id="idSucursal" class="form-control" required>
                      <option value="1">KIA VICTORIA</option>
                      <option value="2">KIA MRA</option>                         
                      <option value="3">KIA AVAY</option>                        
                    </select>
                  </div>
              </div>

            </div>

            <div class="row mr-1 ml-1 mb-3">
              <button type="button" id="abm" class="btn btn-primary" style="margin-left:auto;" (click)="crear()">Crear</button>
              <button type="button" id="cancelar" class="btn btn-danger ml-3" style="margin-right: 8px;" (click)="reset()"><strong>Cancelar</strong></button>
            </div>

            <div class="row mr-1 ml-1">
                <div class="col">
                    <h4 class="bg-danger rounded"><i class="fa fa-database ml-1" aria-hidden="true"></i> LISTADO DE USUARIOS</h4>
                    <div class="table-responsive" style="border-radius: 5px;">
                        <table class="table table-bordered table-striped table-sm table-hover text-nowrap mb-3" id="detalle">
                            <thead>
                              <tr>
                                <th class="pl-1 pr-1">NRO</th>
                                <th>NOMBRE</th>
                                <th>AREA</th>
                                <th>USUARIO</th>
                                <th style="text-align: center;">ESTADO</th> 
                                <th style="text-align: center;">NOTIFICACION</th> 
                                <th class="pl-1 pr-1" style="text-align: center;"><i class="fa fa-cogs" aria-hidden="true"></i></th> 
                              </tr>
                            </thead>
                            <tbody id="listadoUsuario">
                            </tbody>
                        </table>
                    </div>            
                </div>
            </div>
                
          </div>
          <!-- /.card-body -->
    
          <div class="card-footer">
          </div>
        </form>
      </div>
</div>


